import React from 'react';
import PropTypes from 'prop-types';
import cleanKey from 'core/util/cleanKey';
import * as cls from './BannerWrapper.styles.module.scss';
import logger from 'score/logSvc';

const log = logger.getLogger('Link',{}); // eslint-disable-line

export default class BannerWrapper extends React.Component {
  /**
   * propTypes
   * @property {string} children
   * @property {string} width
   */
  static get propTypes() {
    return {
      children: PropTypes.node,
    };
  }

  render() {
    return (
      <div
        className="smartEditComponent"
        data-ssr-key={cleanKey(this.props.SSRKey)}
        data-smartedit-component-id={cleanKey(this.props.SSRKey)}
        data-smartedit-component-uuid={cleanKey(this.props.elementUid)}
        data-smartedit-catalog-version-uuid={cleanKey(this.props.contentSlotCatalogVersion)}
        data-smartedit-component-type={cleanKey(this.props.componentParentType)}
      >
        <div className={'ic-grid-center ' + cls.bannerMargin}>
          <div className="ic-col-12 ic-grid-center">{this.props.children}</div>
        </div>
      </div>
    );
  }
}
